import React from 'react';

const Privacy = ({}
) => {
    return (
        <div className="container">

            <h2 style={{textAlign: "center"}}>PRIVACY POLICY</h2>

            <div className="row mt-4">
                <p className="text-left">
                    This privacy policy (“Policy”) describes the information we collect, how it is used and shared, and
                    your choices regarding this information. By submitting, or allowing a third person to submit, your
                    personal information to Claimmunity, Inc., a Delaware corporation, or any parent, subsidiary or
                    affiliate thereof (collectively, “Claimmunity”, also referred to as “we,” “us,” or a similar
                    construction), you consent to our collection, use, and disclosure or sharing of your personal
                    information as set forth in this Policy. If you do not agree with the terms of this Policy, please
                    do not submit any personal information, or permit the same to be submitted, to us. This Policy is
                    incorporated into, and is a part of, the Terms of Use (the “Terms”) located at claimmunity.com/terms
                    which govern the claimmunity.com website, and related websites, platforms, mobile sites,
                    applications, content, and products and services. Capitalized terms used in this Policy, but not
                    defined in this Policy, have the meanings as defined in the Terms. Our liability from or arising out
                    of this Policy or any breach hereof is subject to the general limits of liability and disclaimers
                    stated in the Terms.
                </p>
            </div>

            <h3 className="row text-left">Scope</h3>
            <div className="row">
                <p className="text-left">
                    This Policy applies to all users of the Services, but excludes websites, mobile sites, applications,
                    and products and services with separate privacy policies which do not incorporate this Policy. This
                    Policy does not apply to third-party websites, mobile sites, applications, or products and services
                    even if accessed via our Services. Personal information means information that identifies you as an
                    individual, or that can be combined with readily available information to identify you as an
                    individual. This Policy does not cover any information (such as anonymized or aggregated demographic
                    information) which is not personally identifiable or financial information, or any ideas,
                    suggestions, or comments you may submit about the Service (including without limitation User
                    Content), which information we may use for any purposes in our sole discretion. This Privacy Policy
                    does not apply to Logistics Data (defined below) unless, and only to the extent that, personal
                    information which is covered by this Policy is included within the Logistics Data, and in such case
                    applies only to the applicable personal information and no other Logistics Data. “Logistics Data”
                    means any data, information, or material provided or submitted to the Services by any and all Users
                    (i.e., submitted by way of an interface with Claimmunity or otherwise), including without
                    limitation, pickup date and time, delivery location, load, and rate (and, as described below,
                    Logistics Data may include personal information).
                </p>
            </div>

            <h3 className="row text-left">Updates</h3>

            <div className="row">
                <p className="text-left">
                    We reserve the right to change this Policy at any time. If we make any material changes to this
                    Policy, we will post a new Policy on the Services and update the effective date set forth above.
                    Therefore, we encourage you to check the effective date of this Policy whenever you visit the
                    Website to see if it has been updated since your last visit. To the extent permitted under
                    applicable law, by using Services after such notice, you consent to our updates to this policy.
                </p>
            </div>

            <h3 className="row text-left">The Information We Collect</h3>

            <div className="row">
                <p className="text-left">
                    The types of information we may collect and how we collect it include:
                </p>
                <p className="text-left">
                    In general, we collect information to allow you to use the Services, process your Account
                    registration, authorize your payment, bill you, respond to your inquiries, communicate with you
                    about the Services and other products and services, and maintain your Account with us.
                </p>
                <p className="text-left">
                    <ul>
                        <li>If you register an Account, we may collect information about you and as necessary to create
                            and/or maintain your Account, such as your name, address, email address, and other contact
                            information, and your billing information, like your credit card number, CVV code, and
                            expiration date, or ACH information including routing and account numbers.
                        </li>
                        <li>In addition to the information described in this Policy, depending on the type of Account
                            you have, as applicable, we may collect other personal information about you. Please review
                            your Account Agreement (if applicable), especially the “Privacy” section, to understand what
                            additional information we may collect.
                        </li>
                        <li>We may collect information about your device and how you interact with our Services such as:
                            IP address (or the DNS name associated with it), browser type, device location, unique
                            device identifier, cookie data, associated identifying and usage information from your
                            mobile phone, laptop, or other device, and other device information that you give us consent
                            to access. In some cases, we collect this information through cookies, pixel tags, and
                            similar technologies that create and maintain unique identifiers.
                        </li>
                        <li>To enable personalization and consistent experiences across products and devices for your
                            Claimmunity accounts, we track and use visitor and account data. Visitor data includes name,
                            email, role, permissions, business title, location, IP address, first login date, and
                            visitor actions (e.g., saw a webinar, received an email, downloaded a whitepaper, etc.).
                            Account data includes account name, location, industry, size, assigned contacts, and
                            activity (e.g., reports downloaded, data exports, feature usage).
                        </li>
                        <li>We may collect other information with your consent, as may be described within the
                            Services.
                        </li>
                        <li>We may also collect your information from licensees, affiliates, partners, service
                            providers, and independent third-party sources.
                        </li>
                        <li>We may combine the information we collect with other information in our possession.</li>

                    </ul>
                </p>
            </div>

            <h3 className="row text-left">Information Related To Drivers</h3>

            <div className="row">
                <p className="text-left">
                    Claimmunity may receive very limited personal information related to drivers. Claimmunity processes
                    that data in the performance of services for and under the direction of our customers. We process
                    this personal information to fulfill our contractual obligations to our customers and premised on
                    the same legal basis they identified to you — such as your consent or fulfilling a contract with
                    you. Personal data collected from individual drivers may include the following: name, address, phone
                    number, email address, social security numbers, driver license numbers, passport numbers, VISA
                    numbers, and green card numbers. PII is not stored in any transactional data, enabling the data to
                    be amended or deleted without impact to transactions. </p>
            </div>

            <h3 className="row text-left">How We Use Your Information</h3>

            <div className="row">
                <p className="text-left">
                    With respect to the personal information Claimmunity collects from you, Claimmunity acts as the
                    controller of your personal information. In this capacity, we only collect, use, share, store, or
                    otherwise process your personal information when we have an appropriate basis. For example, we may
                    process your personal information as necessary to provide the services you request, or to enforce or
                    fulfill our obligations under our terms of use that apply to your engagement. Additionally, your
                    personal information may be used for legitimate interests (e.g., providing and improving the
                    products and services, communicating with you, and improving the user-experiences) if doing so is
                    consistent with your rights and appropriate to the context, and to comply with legal obligations. We
                    will retain your personal information so long as reasonably necessary to fulfill these purposes.
                    Claimmunity also processes personal information under the direction of our customers. In those
                    instances, Claimmunity acts as a processor of the personal information of our customer, the data
                    controller. Claimmunity may share personal information with service providers, affiliates,
                    contractors, and other third parties who help us perform services such as managing communications,
                    administering this Site, or conducting our business. We permit these third parties to use personal
                    information as needed to deliver services or comply with law. We will share personal information in
                    the event we sell or transfer all or a portion of our business assets, such as during a merger,
                    acquisition, liquidation, or bankruptcy. In limited cases, we may share information with other
                    parties if appropriate to respond to your request or inquiry. We also may share personal information
                    if we have a good faith belief doing so is necessary to comply with law, respond to a legitimate
                    request from law enforcement or other government body, to protect our interests or the health and
                    safety of others, or to enforce our terms of use for this Site and/or our products and services.
                </p>
            </div>

            <h3 className="row text-left">Information Sharing and Disclosure</h3>
            <div className="row">
                <p className="text-left">
                    We may share and disclose the information we collect as follows:
                    <ul>
                        <li>We may use your personal information in our development of new products and services which
                            would then be shared with our clients and customers with the personal information
                            anonymized, including products which allow Users to make selections based on performance
                            metrics developed using personal information.
                        </li>
                        <li>In addition to the uses and activities described in this Policy, depending on the type of
                            Account you have, we may share and disclose other personal information we collect from or
                            about you in other ways as specified in other documents provided by Claimmunity. Please
                            review your Account Agreement, especially the “Privacy” section, to understand what
                            additional information we may share and disclose.
                        </li>
                        <li>We may share the information we collect with our parents, subsidiaries and affiliates.</li>
                        <li>We may share your personal information with our service providers and business partners,
                            including our vendors, consultants, marketing partners, research firms, and other service
                            providers or business partners. This may include, for example:
                            <ul>
                                <li>Validation of appropriate registration for Accounts and use of the Services</li>
                                <li>Fraud prevention.</li>
                                <li>Payment processors and facilitators.</li>
                                <li>Cloud storage providers.</li>
                                <li>Marketing partners and marketing platform providers.</li>
                                <li>Data analytics providers.</li>
                                <li>Research partners, including those performing surveys or research projects in
                                    partnership with Claimmunity or on Claimmunity’s behalf.
                                </li>
                                <li>Vendors that assist Claimmunity to enhance the security of the Services.</li>
                                <li>With our insurance partners to offer, obtain, provide, or facilitate insurance or in
                                    connection with our services, and to help determine and provide relevant coverage in
                                    the event of an incident.
                                </li>
                                <li>Consultants, lawyers, accountants, and other professional service providers.</li>
                            </ul>
                        </li>

                    </ul>
                </p>

                <p>
                    Claimmunity may share your personal information with third parties in circumstances such as when it
                    is necessary to provide the Services, fulfill a transaction you have requested, offer you
                    personalized services, or in other circumstances that are permitted by law and consistent with the
                    purposes for which we collected the information. If you register an Account, some of the information
                    you supply may be shared with regulatory bodies and/or service providers we utilize to obtain
                    personal information. Claimmunity may share your information if we believe it is required by
                    applicable law, regulation, operating agreement, legal process or governmental request, or where the
                    disclosure is otherwise appropriate due to safety, security, legal or similar concerns. Claimmunity
                    may disclose your personal information: in response to legal process (for example, a court order,
                    search warrant or subpoena) or requests from law enforcement or regulatory authorities; in other
                    circumstances in which Claimmunity believes the Services are being or have been used in violation of
                    our policies, applicable law or otherwise in the commission of a crime; when we have a good faith
                    belief that there is an emergency that poses a threat to the safety of you or another person; or
                    when necessary to protect the rights or property of Claimmunity or third parties. We may disclose or
                    transfer your personal information in connection with a corporate transaction, proceeding, or
                    reorganization involving the business activities for which the information is maintained. In the
                    event that ownership of Claimmunity or its business was to change as a result of a merger,
                    acquisition, asset sale or transfer to another company, your personal information may be shared or
                    transferred. Claimmunity may share your information other than as described in this Policy if we
                    notify you and you consent to the sharing.
                </p>
            </div>

            <h3 className="row text-left">How We Safeguard Your Information</h3>

            <div className="row">
                <p>
                    We maintain reasonable and adequate technical, administrative, and physical security and
                    confidentiality measures designed to help protect your personal information from unauthorized access
                    or acquisition. We also require by contract (other than in an emergency situation) that third party
                    services providers acting on our behalf or with whom we share your information also undertake to
                    provide such security and confidentiality measures in accordance with industry standards.
                </p>
            </div>

            <h3 className="row text-left">Information Retention & Deletion</h3>

            <div className="row">
                <p>
                    You may request deletion of your Account at any time by contacting us at support@claimmunity.com.
                    Following such request, Claimmunity deletes any information that we are not required to retain
                    (such as information retained in connection with transactions). In certain circumstances,
                    Claimmunity may be unable to delete your Account or information, such as if there is an outstanding
                    credit on your Account or an unresolved claim or dispute. Claimmunity may also retain certain
                    information if necessary for its legitimate business interests, such as information which has
                    already been shared with others (in connection with transactions or otherwise), information which is
                    not personally-identifiable data which we will use internally or in aggregate reports (as described
                    in this Policy), as well as fraud prevention and enhancing users’ safety and security. For example,
                    if Claimmunity suspends or terminates your Account because of unsafe behavior or security incidents,
                    Claimmunity may retain certain information about that Account to prevent you from opening a new
                    Claimmunity Account in the future. </p>
            </div>

            <h3 className="row text-left">Information Retention & Deletion</h3>
            <div className="row">
                <p>
                    You may request that Claimmunity:
                    <ul>
                        <li>Provide a detailed explanation regarding the information Claimmunity has collected about you
                            and how we use that information.
                        </li>
                        <li>Receive a copy of the information Claimmunity has collected about you.</li>
                        <li>Request correction of any inaccurate information that Claimmunity has about you.</li>
                    </ul>
                    You can make these requests by contacting Claimmunity at support@claimmunity.com. You can update the
                    name, phone number, and email address associated with your Account within the administration section
                    of your Account. Marketing Opt-Outs: You may opt out of receiving promotional emails from
                    Claimmunity by contacting Claimmunity at support@claimmunity.com. You may also opt out of receiving
                    emails and other messages from Claimmunity by following the instructions in those messages. Please
                    note that if you opt out, we may still send you non-promotional messages, such as information about
                    your Account. We may also provide methods of opting out through Account controls.
                </p>
            </div>

            <h3 className="row text-left">Cookies</h3>
            <div className="row">
                <p>
                    Claimmunity uses cookies, content, and other technologies that are placed in the browser on your
                    computer or mobile device, and can be used to help recognize you on our Services and improve the
                    user experience. We use both session ID cookies and persistent cookies. A session ID cookie expires
                    when you close your browser. We use session cookies to maintain session state and user identity. A
                    persistent cookie remains on your hard drive for an extended period of time. We use persistent
                    cookies and content to help remember user preferences and improve the user experience. You may be
                    able to remove persistent cookies by following directions provided in your browser’s “help” file.
                    You can control browser cookies through your browser’s settings. The use of cookies, web beacons, or
                    similar technologies by content and ad network providers is subject to their own privacy policies,
                    not ours.
                </p>
            </div>

            <h3 className="row text-left">Protection of Children</h3>
            <div className="row">
                <p>
                    While the Services may be viewed by children, we do not wish to receive data from or about children.
                    We encourage parents and guardians to monitor and supervise all Internet usage by children. No
                    information should be submitted to, or posted at, the Services by visitors under 18 years of age
                    without the consent of a parent or guardian.
                </p>
            </div>

            <h3 className="row text-left">Your California Privacy Rights</h3>
            <div className="row">
                <p>
                    If you are a resident of the State of California, you are entitled under California law, including
                    the California Consumer Privacy Act of 2018 (“CCPA”), to certain additional information about, and
                    additional rights with respect to, our collection and disclosure of your personal information. This
                    Section provides additional information and describes those additional rights. This Section applies
                    solely to Site visitors and customers who reside in the State of California and to personal
                    information as defined under CCPA.
                </p>
            </div>

            <h3 className="row text-left">Other Rights And Choices Regarding Your Personal Information Under CCPA</h3>
            <div className="row">
                <p>
                    You have the right to request we delete any of your personal information we collected from you and
                    retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer
                    request, we will delete (and direct our service providers to delete) your personal information from
                    our records, unless an exception applies. Only you, or a person registered with the California
                    Secretary of State you authorize to act on your behalf, may make a verifiable consumer request
                    related to your personal information. We will endeavor to respond to a verifiable consumer request
                    within forty-five (45) days of its receipt. If we require more time, we will inform you of the
                    reason and extension period in writing. If you have an account with us, we will deliver our written
                    response to that account. If you do not have an account with us, we will deliver our written
                    response by mail or electronically, at your option. Any disclosures we provide will only cover the
                    12-month period preceding the verifiable consumer request’s receipt. The response we provide will
                    also explain the reasons we cannot comply with a request, if applicable. We will not charge a fee to
                    process or respond to your verifiable consumer request unless it is excessive, repetitive, or
                    manifestly unfounded. If we determine the request warrants a fee, we will tell you why we made that
                    decision and provide you with a cost estimate before completing your request. You may only make a
                    verifiable consumer request for access or data portability twice within a 12-month period. The
                    verifiable consumer request must provide sufficient information that allows us to reasonably verify
                    you are the person about whom we collected personal information, and describe your request with
                    sufficient detail that allows us to properly understand, evaluate, and respond to it. We cannot
                    respond to your request or provide you with personal information if we cannot verify your identity
                    or authority to make the request and confirm the personal information relates to you. You have the
                    right not to be discriminated against for exercising any of your CCPA rights. However, to the extent
                    permitted by the CCPA, we may offer you certain financial incentives for the collection or sharing
                    or your personal information that can result in different prices, rates, or quality levels. Any
                    CCPA-permitted financial incentive we offer will reasonably relate to your personal information’s
                    value and contain written terms that describe the program’s material aspects. Participation in a
                    financial incentive program requires your prior opt-in consent, which you may revoke at any time.
                </p>
            </div>

            <h3 className="row text-left">Sales And Disclosures Of Personal Information For A Business Purpose</h3>
            <div className="row">
                <p>
                    We may disclose your personal information to a third party for a business purpose or sell your
                    personal information, subject to your right to opt-out of those sales. You have the right to direct
                    us to not sell your personal information at any time (the right to “opt-out”). If you opt-in to
                    personal information sales, you may opt-out of future sales at any time. To exercise your rights
                    under this Section, you (or your authorized representative) should send your request as described in
                    the <b>“Contact Information”</b> Section below. Once you make an opt-out request, we will wait at
                    least
                    twelve (12) months before asking you to reauthorize personal information sales. You do not need to
                    create an account with us to exercise your opt-out rights. We will only use personal information
                    provided in an opt-out request to review and comply with the request. </p>
            </div>

            <h3 className="row text-left">Contact Us</h3>
            <div className="row">
                <p>
                    If you have questions or complaints regarding our Policy or practices, please contact us at
                    support@claimmunity.com.
                </p>
            </div>

        </div>
    )
}

export default Privacy;
