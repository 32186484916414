import {ThemeProvider} from "styled-components";
import {saasTheme} from "common/src/theme/saas";
import React from 'react';
import Privacy from "../containers/Saas/Privacy";

export default () => {
    return (
        <ThemeProvider theme={saasTheme}>
            <Privacy/>
        </ThemeProvider>
    )
};
